<template lang="">
    <div class="container">
        <div class="text text-center">
            <p>GO-SWITCH!</p>
        </div>
        <div class="main-button">
            <v-btn fab icon width="auto" height="auto" @click=startStop()>
                <v-avatar size="min(80vw, 33rem)">
                    <img :src='imgURL' alt="test"/>
                </v-avatar>
            </v-btn>
        </div>
    </div>
</template>
<script>

export default {
    created() {
        this.$root.$refs.mainPage_component = this;
    },
    data() {
        return {
            nextAction: "start",
            imgURL: require('../assets/freddy_button_start.svg'),
            timeoutId: null,
            currentInterval_min: 2,
            currentInterval_max: 12,
            numberOfSounds: 2,
            currentRandomInterval: null,
        }
    },
    methods: {
        startStop: function () {
            if (this.nextAction == 'start') {
                this.startRandomSoundService()
                this.nextAction = 'stop'
            }
            else if (this.nextAction == 'stop') {
                this.stopRandomSoundService()
                this.nextAction = 'start'
            }

            this.changeIMG()
        },
        changeIMG: function () {
            this.imgURL = require('../assets/freddy_button_' + this.nextAction + '.svg')
        },
        playSoundXTimes: function () {
            let numberOfTimesPlayed = 0;
            const randomValue = this.calculateRandomSound()
            const audioURL = require('../assets/sounds/ding.mp3');
            let audio = new Audio(audioURL);

            audio.addEventListener("ended", () => {
                if (numberOfTimesPlayed != randomValue - 1) {
                    numberOfTimesPlayed++
                    audio.play()
                }
            });
            // Play the audio
            audio.play()
        },
        startRandomSoundService: function () {
            this.calculateRandomInterval()
            this.timeoutId = setTimeout(() => {
                this.playSoundXTimes()
                this.startRandomSoundService();
            }, this.randomInterval);
        },
        stopRandomSoundService: function () {
            clearTimeout(this.timeoutId)
        },
        calculateRandomInterval: function () {
            const randomNumber = Math.floor((Math.random()) * (this.currentInterval_max + 1 - this.currentInterval_min) + this.currentInterval_min)
            this.randomInterval = randomNumber * 1000;
        },
        calculateRandomSound: function () {
            const randomNumber = Math.floor((Math.random() * (this.numberOfSounds)) + 1);
            return randomNumber;
        },
        changeRange: function () {
            this.currentInterval_min = this.$root.$refs.settingsPage_component.interval_range[0];
            this.currentInterval_max = this.$root.$refs.settingsPage_component.interval_range[1];
        },
        changeNumberOfSounds: function () {
            this.numberOfSounds = this.$root.$refs.settingsPage_component.number_of_sounds;
        },

    }

}
</script>
<style lang="scss">

body,
h1,
h2,
h3,
p {
  margin: 0;
}
.main-button {
    margin-top: 15vh;
    text-align: center;
}

/* Go- Switch! */
.text {
    font-family: 'ResotFlags';
    /* font-style: italic; */
    font-weight: 900;
    font-size: min(18vw, 7rem);
    line-height: 1;
    margin-top: 2rem;


    color: #ffffff;
}
.text-center {
    text-align: center;
}
.container {
    margin-inline: auto;
    width: min(90%, 70.5rem);
    height: min(90%, 70.5rem);
}
</style>