<template>
  <v-app id="inspire">
    <v-navigation-drawer v-model="drawer" app>
      <!--  -->
      <SettingsPage></SettingsPage>
    </v-navigation-drawer>

    <v-app-bar color="#000000" dark app>
      <v-app-bar-nav-icon x-large @click="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title></v-toolbar-title>
    </v-app-bar>

    <v-main>
      <MainPage></MainPage>
    </v-main>
  </v-app>
</template>

<script>
import SettingsPage from './components/SettingsPage.vue';
import MainPage from './components/MainPage.vue';

export default {
  data: () => ({ drawer: null }),
  components: { SettingsPage, MainPage }
}

</script>
<style>
@font-face {
  font-family: "ResotFlags";
  src: local("ResotFlags"), url(./assets/fonts/Resotflagsusap-DOYe1.ttf) format("truetype");
}
@font-face {
  font-family: "Monomaniac One";
  src: local("Monomaniac One"), url(./assets/fonts/monomaniac-one-regular.ttf) format("truetype");
}
</style>