<template lang="">
    <div>
        <div class="container">
            <div class="header split">
                <v-icon color="white" x-large>mdi-cog</v-icon>
                <h1>Settings</h1>
            </div>
            <hr>
            <div>
                <h2>Interval</h2>
                <p class="font-weight-thin">change the random interval</p>
                <div class="slider">
                    <v-range-slider
                        v-model="interval_range"
                        thumb-label = "always"
                        color="red"
                        min="1"
                        max="15"
                        ticks="always"
                        tick-size="4"
                        tick-color="black"
                    ></v-range-slider>
                </div>
            </div>
            <div>
                <h2>Number of Sounds</h2>
                <p class="font-weight-thin">choose the number of different sounds</p>
                <div class="slider">
                    <v-slider
                        v-model="number_of_sounds"
                        thumb-label="always"
                        color="red"
                        value="2"
                        min="1"
                        max="5"
                        ticks="always"
                    ></v-slider>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    created() {
        this.$root.$refs.settingsPage_component = this;
    },
    data: () => ({
        number_of_sounds: 2,
        interval_min: 2,
        interval_max: 7,
        interval_range: [2, 12]
    }),
    methods: {

    },
    watch: {
        interval_range(newRange, oldRange) {
            if (newRange != oldRange)
                this.$root.$refs.mainPage_component.changeRange();
        },
        number_of_sounds(newNumber, oldNumber) {
            if (newNumber != oldNumber)
                this.$root.$refs.mainPage_component.changeNumberOfSounds();
        },
    },
}
</script>
<style lang="scss">
h1,
h2,
h3 {
  color: white;
  font-family: "Monomaniac One", ;
}
.slider {
    margin-top: 40px;
}
.container {
    margin-inline: auto;
    margin-top: 10px;
    width: min(90%,70.5rem);
}
.split {
  display: flex;
  flex-direction: row;
}
hr {
    margin-top: 20px;
    margin-bottom: 20px;
}
</style>